//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
export default {
  name: 'FormSearch',
  props: {
    placeholder: {
      type: String,
      required: false,
      default: ''
    },
    value: {
      type: undefined,
      required: true
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false
    },
    type: {
      type: String,
      required: false,
      default: 'text'
    }
  },
  data: function data() {
    return {
      id: null,
      fieldValue: this.value
    };
  },
  watch: {
    value: function value() {
      this.fieldValue = this.value;
    }
  },
  mounted: function mounted() {
    this.id = 'this._uid';
  },
  methods: {
    handleInput: function handleInput() {
      this.$emit('change', this.fieldValue);
      this.$emit('input', this.fieldValue);
    }
  }
};