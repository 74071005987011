/* unplugin-vue-components disabled */import __unplugin_components_14 from '/workspace/src/components/FeatherIcon.vue';
import __unplugin_components_13 from '/workspace/src/components/DialogModal.vue';
import __unplugin_components_12 from '/workspace/src/components/FeatherIcon.vue';
import __unplugin_components_11 from '/workspace/src/components/ButtonGroup.vue';
import __unplugin_components_10 from '/workspace/src/components/Chip.vue';
import __unplugin_components_9 from '/workspace/src/components/FeatherIcon.vue';
import __unplugin_components_8 from '/workspace/src/components/ImageThumbnail.vue';
import __unplugin_components_7 from '/workspace/src/components/TableSimple.vue';
import __unplugin_components_6 from '/workspace/src/components/FeatherIcon.vue';
import __unplugin_components_5 from '/workspace/src/components/FeatherIcon.vue';
import __unplugin_components_4 from '/workspace/src/components/PageSection.vue';
import __unplugin_components_3 from '/workspace/src/components/FormSearch.vue';
import __unplugin_components_2 from '/workspace/src/components/ButtonCustom.vue';
import __unplugin_components_1 from '/workspace/src/components/PageHeader.vue';
import __unplugin_components_0 from '/workspace/src/components/PageContainerScroll.vue';
var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(__unplugin_components_0,[_c(__unplugin_components_1,{attrs:{"title":_vm.$t('#Series #Title Series')}},[_c(__unplugin_components_2,{attrs:{"variant":"primary","icon":"PlusIcon","title":_vm.$t('#Button Create Series')},on:{"click":function($event){return _vm.$router.push('/lab/create-series')}}}),_c(__unplugin_components_3,{attrs:{"placeholder":_vm.$t('#Placeholder Search')},nativeOn:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.find()}},model:{value:(_vm.pagination.search),callback:function ($$v) {_vm.$set(_vm.pagination, "search", $$v)},expression:"pagination.search"}})],1),_c(__unplugin_components_4,{attrs:{"loading":_vm.loading}},[_c('div',{staticClass:"pagination"},[_c('span',[_vm._v(" "+_vm._s(((_vm.$t("#Pagination Page")) + " " + (_vm.pagination.page) + " " + (_vm.$t("#Pagination of")) + " " + _vm.getTotalPages))+" ")]),_c('b-pagination',{attrs:{"pills":"","hide-goto-end-buttons":"","variant":"secondary","total-rows":_vm.pagination.total,"per-page":_vm.pagination.limit},on:{"change":_vm.find},scopedSlots:_vm._u([{key:"prev-text",fn:function(){return [_c(__unplugin_components_5,{attrs:{"icon":"ArrowLeftIcon"}})]},proxy:true},{key:"next-text",fn:function(){return [_c(__unplugin_components_6,{attrs:{"icon":"ArrowRightIcon"}})]},proxy:true}]),model:{value:(_vm.pagination.page),callback:function ($$v) {_vm.$set(_vm.pagination, "page", $$v)},expression:"pagination.page"}})],1),_c(__unplugin_components_7,{attrs:{"headers":_vm.headers,"items":_vm.series},scopedSlots:_vm._u([{key:"cell(image)",fn:function(ref){
var item = ref.item;
return [_c(__unplugin_components_8,{attrs:{"src":item.defaultImageUrl}})]}},{key:"cell(title)",fn:function(ref){
var item = ref.item;
return [_c('h4',[_vm._v(" "+_vm._s(_vm.getTranslated(item.languages, 'title'))+" ")]),(Object.keys(item.category.languages).length > 0)?_c('p',[_vm._v(" "+_vm._s(item.category.languages[_vm.$i18n.locale].title)+" ")]):_vm._e(),_c('a',{staticClass:"share-link",on:{"click":function($event){return _vm.shareLink(item.slug)}}},[_c('span',[_vm._v("Link de compartilhamento")]),_c(__unplugin_components_9,{attrs:{"icon":"ChevronRightIcon","size":"15"}})],1)]}},{key:"cell(status)",fn:function(ref){
var item = ref.item;
return [_c(__unplugin_components_10,{style:(_vm.statusTagStyle(item.status))},[_vm._v(" "+_vm._s(_vm.$t(item.status ? item.status : 'no status'))+" ")])]}},{key:"cell(workload)",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(" "+_vm._s(item.workload + " " + _vm.$t("#Noun hours"))+" ")])]}},{key:"cell(price)",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(" "+_vm._s(_vm.getPrice(item.price))+" ")])]}},{key:"cell(actions)",fn:function(ref){
var item = ref.item;
return [_c(__unplugin_components_11,[_c('b-dropdown',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(113, 102, 240, 0.15)'),expression:"'rgba(113, 102, 240, 0.15)'",modifiers:{"400":true}}],staticClass:"dropdown-icon-wrapper",attrs:{"variant":"outline-secondary"},scopedSlots:_vm._u([{key:"button-content",fn:function(){return [_c('b',[_c(__unplugin_components_12,{attrs:{"icon":"ChevronDownIcon","size":"20"}})],1)]},proxy:true}],null,true)},[_c('b-dropdown-item-button',{on:{"click":function($event){return _vm.deleteSeries(item)}}},[_vm._v(" "+_vm._s(_vm.$t("#Button Delete"))+" ")]),_c('b-dropdown-item-button',{on:{"click":function($event){return _vm.onEditSeries(item)}}},[_vm._v(" "+_vm._s(_vm.$t("#Button Edit"))+" ")])],1),_c('router-link',{staticClass:"link",attrs:{"to":{ name:'lab-open-series', params: { seriesId: item.slug, currentPage: 'levels' } }}},[_vm._v(" "+_vm._s(_vm.$t('#Button Open'))+" ")])],1)]}}])})],1),_c(__unplugin_components_13,{attrs:{"has-close":true},model:{value:(_vm.linkModal),callback:function ($$v) {_vm.linkModal=$$v},expression:"linkModal"}},[_c('div',{staticClass:"link-modal"},[_c('h5',[_vm._v("Aqui está seu link de indicação")]),_c('code',[_c('span',[_vm._v(_vm._s(_vm.creatorLink))]),_c(__unplugin_components_14,{attrs:{"icon":"CopyIcon","size":"20"},on:{"click":_vm.copy}})],1)])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }