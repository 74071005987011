//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
export default {
  name: 'DialogModal',
  props: {
    value: {
      type: Boolean,
      required: false,
      default: false
    },
    hasClose: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data: function data() {
    return {
      isVisible: this.value
    };
  },
  watch: {
    value: function value() {
      this.isVisible = this.value;
    }
  },
  methods: {
    collapseDrawer: function collapseDrawer() {
      this.isVisible = false;
      this.$emit('input', this.isVisible);
    }
  }
};